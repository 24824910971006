// App.js or App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//  import AboutPage from './pages/AboutPage';
//  import ContactPage from './pages/ContactPage';
import Navbar from './components/Navbar'; // Assuming you have a Navbar component
import Home from './pages/home';
import Footer from './components/Footer';
import About from './pages/about';
import Contact from './pages/contact';
import Services from './pages/services';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About/>} />
         <Route path="/contact" element={<Contact />} />
         <Route path="/services" element={<Services />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
