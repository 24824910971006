// pages/ContactUs.js
import React from 'react';
import { PiMapPinAreaFill } from "react-icons/pi";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

export default function Contact() {
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission

    // Get form data
    const formData = new FormData(event.target);
    const name = formData.get('name');
    const email = formData.get('email');
    const message = formData.get('message');

    // Construct the mailto URL with prefilled data
    const mailtoLink = `mailto:info@thirty3options.com?subject=Contact Us - ${encodeURIComponent(name)}&body=${encodeURIComponent(`${message}`)}`;

    // Open the email client
    window.location.href = mailtoLink;
  };

  return (
    <section className="bg-white mt-2 py-16 px-6">
      <div className="max-w-7xl mx-auto px-4">
        <h1 className="text-4xl font-extrabold text-gray-900 mb-8">Contact Us</h1>
        <div className="flex flex-col md:flex-row gap-12">
          {/* Contact Form */}
          <div className="flex-1 bg-white border shadow-lg rounded-lg p-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">Send Us a Message</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-lg  text-gray-700">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  required
                  className="mt-1 block w-full h-[40px] p-2p border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-lg  text-gray-700">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  className="mt-1 block w-full h-[40px] p-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="message" className="block  text-lg  text-gray-700">Message</label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  required
                  className="mt-1 block w-full border p-2 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <button
                type="submit"
                className="justify-center h-[40px]  px-4 text-center text-white uppercase shadow-md bg-[linear-gradient(90deg,#D19D2A_0%,#E3C257_50.39%,#D19D2A_100%)] rounded-r-[100px]"
              >
                Submit
              </button>
            </form>
          </div>
          
          {/* Contact Information */}
          <div className="flex-1 bg-white border shadow-lg rounded-lg p-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">Contact Information</h2>
            <p className="text-lg flex gap-3 items-center text-gray-700 mb-4">
              <strong><PiMapPinAreaFill size={35} /></strong> Regus - Kampala, Acacia Mall
            </p>
            <p className="text-lg flex gap-6 items-center text-gray-700 mb-4">
              <strong><BsFillTelephoneFill size={25} /></strong> 031 2531500
            </p>
            <p className="text-lg flex gap-5 items-center text-gray-700 mb-4">
              <strong><MdEmail size={28} /></strong> info@thirty3options.com
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
