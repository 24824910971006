import React from 'react';

export default function Footer() {
    const SocialMediaIcons = ({ icons }) => (
      <div className="flex gap-5 items-center mt-5">
        {icons.map((icon, index) => (
          <img key={index} loading="lazy" src={icon.src} alt={icon.alt} className={`shrink-0 self-stretch my-auto w-6 aspect-square ${icon.extraClass || ""}`} />
        ))}
      </div>
    );
  
    const ServiceList = ({ title, services }) => (
      <section className="flex flex-col ml-5 w-full max-md:ml-0 max-md:w-full">
        <h2 className="flex flex-col grow mt-4 font-medium max-md:mt-10">
          <span className="text-lg tracking-wider leading-7 text-white uppercase">{title}</span>
        </h2>
        <ul className="flex flex-col mt-6 text-base leading-8 text-gray-200">
          {services.map((service, index) => (
            <li key={index} className="mt-1">{service}</li>
          ))}
        </ul>
      </section>
    );
  
    const SubscriptionForm = () => (
      <form className="flex flex-col mt-4 font-medium max-md:mt-10">
        <label htmlFor="emailInput" className="sr-only">Your email</label>
        <input type="email" id="emailInput" placeholder="Your email" aria-label="Your email" className="justify-center items-start px-8 py-7 text-xs leading-5 text-gray-300 bg-white rounded-md max-md:px-5" />
        <button type="submit" className="justify-center items-center px-8 py-6 mt-3.5 text-lg tracking-wider leading-7 text-center text-amber-500 uppercase whitespace-nowrap bg-white rounded-md max-md:px-5">Contact</button>
      </form>
    );
  
  
    return (
      <div className="flex justify-center items-center px-16 py-11 mt 2 w-full bg-gray-900 max-md:px-5 max-md:mt-10 max-md:max-w-full">
            
          <footer className="shrink-0 mt-16 h-px border border-solid bg-slate-800 border-slate-800 max-md:mt-10 max-md:max-w-full" />
          <nav className="flex gap-5 justify-between px-0.5 mt-10 w-full text-xs font-medium leading-3 text-white uppercase max-md:flex-wrap max-md:max-w-full">
            <div className="justify-center">Copyright © 2024</div>
            <ul className="flex gap-4 justify-between">
              <li><a href="#">Term of use</a></li>
              <li><a href="#">Privacy Policy</a></li>
              <li><a href="#">Cookie Policy</a></li>
            </ul>
          </nav>
   
      </div>
    );
  }