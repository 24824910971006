import * as React from 'react';
import { LuActivity } from "react-icons/lu";
import ReactPlayer from 'react-player'
import { ScrollContainer, GellyElement, HorizontalSection } from "react-nice-scroll";
import "react-nice-scroll/dist/styles.css";
import TypingEffect from 'react-typing-effect';
import { motion } from 'framer-motion';
import { PiMapPinAreaFill } from "react-icons/pi";
import { GiAfrica } from "react-icons/gi";
import { FaChartLine } from "react-icons/fa";
import { BsRocketTakeoffFill } from "react-icons/bs";
import { BsBank2 } from "react-icons/bs";

import { GrCycle } from "react-icons/gr";

import { Link } from 'react-router-dom'; // Import Link
import { FaCode } from "react-icons/fa";

function Banner() {
    const locations = [
        { name: 'Uganda', position: { top: '40%', left: '60%' } },
        { name: 'Kenya', position: { top: '35%', left: '70%' } },
        { name: 'Tanzania', position: { top: '60%', left: '65%' } },
        { name: 'Ghana', position: { top: '30%', left: '30%' } },
        { name: 'Zambia', position: { top: '70%', left: '50%' } },
        { name: 'Rwanda', position: { top: '50%', left: '55%' } },
    ];

    return (
        <section className="bg-cover md:px-16 bg-center md:py-24 text-black text-center relative" style={{ backgroundImage: "url('/path-to-your-background-image.jpg')" }}>

            <div className="container mx-auto px-4">
                <div className="flex flex-col md:flex-row items-center md:items-start">
                    <div className="mb-12 mt-12 md:w-1/2 text-center md:text-left px-4">
                        <h1
                            data-aos="fade-bup"
                            data-aos-delay="100"
                            className="text-5xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl font-bold text-[#172554] text-heading-1">
                            Empowering FinTechs in <span className="text-transparent text-5xl px-2 bg-clip-text bg-[linear-gradient(90deg,#D19D2A_0%,#E3C257_50.39%,#D19D2A_100%)] from-[#d88500] from-90% via-primary via-30% to-[#d88500]">Emerging Markets</span>
                            <span className="text-transparent px-2 bg-clip-text bg-[linear-gradient(90deg,#D19D2A_0%,#E3C257_50.39%,#D19D2A_100%)] from-[#d88500] from-90% via-primary via-30% to-[#d88500]">
                                <span className="hidden md:inline">
                                    <TypingEffect
                                        text={['Emerging Markets']}
                                        speed={100}
                                        eraseSpeed={50}
                                        eraseDelay={2000}
                                        typingDelay={100}
                                        cursorRenderer={cursor => <>{cursor}</>}
                                    />
                                </span>
                            </span>
                        </h1>
                        <p className="text-lg sm:text-xl leading-7 font-normal my-6">
                            Thirty3 Options is a premier management consulting firm focused on guiding FinTech companies in emerging markets. We offer expert advisory services to help navigate market complexities and drive sustainable growth.<br /> <br /> Operating across Africa, including Uganda, Kenya, Tanzania, Ghana, Zambia, and Rwanda, we empower businesses to leverage our expertise and succeed in diverse environments.
                        </p>
                        <div className="text-center md:text-left">
                            <Link to="/contact">
                                <button
                                    className="w-full max-w-xs h-[40px] text-sm px-4 text-center text-white uppercase shadow-md bg-[linear-gradient(90deg,#D19D2A_0%,#E3C257_50.39%,#D19D2A_100%)] rounded-r-[100px]"
                                >
                                    Contact Us
                                </button>
                            </Link>
                        </div>
                    </div>

                    <div className="mt-12 md:mt-0 md:w-1/2 relative overflow-hidden">
                        <img
                            src="/africa.png"
                            alt="Africa Map"

                            className="mx-auto w-full max-w-xs md:max-w-lg"
                            animate={{ x: 100 }}
                            transition={{ ease: "easeOut", duration: 2 }}
                        />

                        {locations.map((location, index) => (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: [0, 1, 1, 0], y: [50, 0, 0, 50] }}
                                transition={{
                                    duration: 2,
                                    ease: "easeOut",
                                    repeat: Infinity,
                                    repeatType: "loop",
                                    times: [0, 0.5, 0.75, 1] // Adjusts the timing of the opacity and y changes
                                }}
                                className="absolute"
                                style={{ top: location.position.top, left: location.position.left, transform: 'translate(-50%, -50%)' }}
                            >
                                <PiMapPinAreaFill size={35} color='#172554' />
                            </motion.div>
                        ))}
                    </div>
                </div>
            </div>
        </section>


    );
}

function OurServices() {

    const services = [
        {
            id: '1',
            title: 'Market entry strategy',
            description: 'We help fintech companies expand into new markets ensuring a smooth and successful entry.',
            icon: <GiAfrica color="#172554" size={35} />
        },
        {
            id: '2',
            title: 'Regulatory Compliance ',
            description: 'We simplify regulatory compliance, ensuring your operations remain compliant with local regulations.',
            icon: <BsBank2 color="#172554" size={30} />
        },
        {
            id: '3',
            title: 'Product launch ',
            description: 'We empower fintech companies by offering a holistic suite of services to bring your product to market efficiently and confidently.',
            icon: <BsRocketTakeoffFill color="#172554" size={30} />
        },
        {
            id: '4',
            title: 'Expansion strategies',
            description: 'We develop tailored strategies by leveraging industry relationships and local expertise to drive sustainable growth.',
            icon: <FaChartLine color="#172554" size={30} />
        },
        {
            id: '5',
            title: 'Market operations',
            description: 'We orchestrate the complete setup necessary for a company and its products to thrive in a new market.',
            icon: <GrCycle color="#172554" size={35} />
        },
        {
            id: '6',
            title: 'SDaaS',
            description: 'Our team delivers custom software solutions from concept to implementation, driving innovation and growth.',
            icon: <FaCode color="#172554" size={30} />
        }
    ];

    const [expandedService, setExpandedService] = React.useState(null);


    return (
        <section id="services" className="flex flex-col justify-end px-4 md:px-16 py-12 w-full">
            <article className="flex flex-col items-center self-center max-w-full font-medium w-full md " data-aos="fade-up">
                <h2 className="self-stretch mt-4 text-4xl md:text-5xl font-semibold text-center text-[#172554] leading-snug md:leading-[75.95px]">
                    Explore Our Services Today!

                </h2>
                <p className="mt-4 text-lg md:text-xl font-normal leading-8 text-center  ">
                    We offer a full suite of services to ensure your fintech company thrives. Our solutions are designed to address every aspect of your business needs, providing you with the tools and expertise to achieve sustainable growth and success.              </p>
            </article>

            <div className="mt-10 ">
                <div className="overflow-x-auto">

                    <div className="my-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {services.map((service, index) => (
                            <div
                                key={index}
                                className={` shadow-md px-4 py-10 border rounded-lg ${index % 2 !== 0 ? 'md:border-x md:border-gray-200' : ''}`}
                            >
                                <div className="flex justify-center items-center bg-[linear-gradient(90deg,#D19D2A_0%,#E3C257_50.39%,#D19D2A_100%)] h-[60px] rounded-[90px] w-[60px] mx-auto">
                                    {service.icon}
                                </div>
                                <Link to={`/services#${service.id}`}><h3 className="text-2xl font-bold mt-2 leading-9 text-[#172554] text-center">{service.title}</h3></Link>
                                <p className="mt-4 text-lg font-medium   font-normal leading-8 text-[black] text-center">
                                    {service.description}

                                </p>
                            </div>
                        ))}
                    </div>


                </div>
            </div>

            {/* <section className="self-center mb-4 mt-12 w-full max-w-[1320px] max-md:max-w-full">
                <div className="flex items-center justify-center my-4">
                    <h1 data-aos="zoom-up" data-aos-delay="200" className="text-4xl font-semibold text-[#172554] mb-8">
                        Market Insights
                    </h1>
                </div>
                <div className="flex flex-wrap justify-center">
                    {flags.map((res, index) => (
                        <div
                            key={index}
                            className="text-center"
                            data-aos="fade-up"
                            data-aos-delay={`${index * 100}`}
                            data-aos-duration="800" // Duration of the animation
                            data-aos-easing="ease-in-out" // Easing function
                        >
                            <img
                                src={res.img}
                                alt={res.name}
                                className="w-[100px] h-[100px] mx-6 rounded"
                            />
                            <small>{res.name}</small>
                        </div>
                    ))}
                </div>
            </section> */}
        </section>


    );
}
export default function Home() {



    return (
        <div className="flex flex-col">
            <Banner />
            <OurServices />
        </div>
    );
}