// pages/Services.js
import React from 'react';
import { GiAfrica } from 'react-icons/gi';
import { BsBank2, BsRocketTakeoffFill } from 'react-icons/bs';
import { FaChartLine, FaCode } from 'react-icons/fa';
import { GrCycle } from 'react-icons/gr';

const services = [
  {
    id: '1',
    title: 'Market entry strategy',
    description: 'We facilitate the market expansion of fintech companies by providing comprehensive support in market entry. Our services encompass market research, market segmentation, and expert guidance on legal and compliance matters, ensuring a smooth and calculated entry into new markets, maximising your chances of success.',
    icon: <GiAfrica color="#172554" size={35} />
  },
  {
    id: '2',
    title: 'Regulatory Compliance and Risk Management',
    description: 'Navigating the regulatory landscape can be complex and challenging. We offer specialised services to help you achieve and maintain compliance with local regulations. Our risk management services identify potential risks and implement strategies to mitigate them, ensuring your operations remain secure and compliant.',
    icon: <BsBank2 color="#172554" size={30} />
  },
  {
    id: '3',
    title: 'Product launch',
    description: 'We empower fintech companies by offering a holistic suite of services, including product roadmap development, product-market fit analysis, MVP identification, tech and development support, partnership identification, and proficient project management. We provide the essential tools and expertise to bring your product to market efficiently, and with confidence.',
    icon: <BsRocketTakeoffFill color="#172554" size={30} />
  },
  {
    id: '4',
    title: 'Expansion strategies',
    description: 'We empower established fintech companies to broaden their horizons through tailored expansion strategies, capitalising on our extensive industry relationships and local expertise. We develop and execute strategies that realise emerging opportunities and drive sustainable growth.',
    icon: <FaChartLine color="#172554" size={30} />
  },
  {
    id: '5',
    title: 'Market operations',
    description: 'We orchestrate the complete setup necessary for a company and its products to thrive in a new market. This encompasses the identification and implementation of processes, robust product support, the establishment of exceptional customer experiences, the acquisition of top-tier talent and strategic marketing initiatives. With our comprehensive approach, we ensure that your operations are optimised for success in any market.',
    icon: <GrCycle color="#172554" size={35} />
  },
  {
    id: '6',
    title: 'SDaaS',
    description: 'We offer software development as a service by leveraging our team of experienced developers to build custom software solutions tailored to your specific requirements and business objectives. From concept to implementation, we deliver high-quality, scalable software solutions that drive innovation and growth for your company.',
    icon: <FaCode color="#172554" size={30} />
  }
];

export default function Services() {
  return (
    <section className="bg-white py-16 px- mt-2 md:mt-6">
      <div className="max-w-7xl mx-auto px-4">
        <h1 className="text-4xl font-extrabold text-[#172554] mb-8 text-center">Our service offering</h1>
        <div className=" gap-8">
          {services.map((service, index) => (
            <div
            id={service.id}
              key={index}
              className={`shadow-md px-4 py-10 border rounded-lg ${index % 2 !== 0 ? 'md:border-x md:border-gray-200' : ''}`}
              data-aos="fade-up"
              data-aos-delay={`${index * 100}`}
              data-aos-duration="800"
              data-aos-easing="ease-in-out"
            >
              <div className="flex justify-center items-center bg-[linear-gradient(90deg,#D19D2A_0%,#E3C257_50.39%,#D19D2A_100%)] h-[60px] rounded-[90px] w-[60px] mx-auto">
                {service.icon}
              </div>
              <h3 className="text-2xl font-bold mt-2 leading-9 text-[#172554] text-center">{service.title}</h3>
              <p className="mt-4 text-lg font-medium leading-8 text-gray-700 text-center">
                {service.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
