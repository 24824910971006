// pages/CompanyOverview.js
import React from 'react';
import Team from '../components/Team';

export default function About() {
  return (
    <section className="relative bg-white mt-2 md:mt-8 py-16 px-6 overflow-hidden">
      <div className="relative max-w-7xl mx-auto px-4">
        <h1 data-aos="zoom-up" data-aos-delay="200" className="text-4xl font-extrabold text-gray-900 mb-8">
          Our Story
        </h1>
        <div className="bg-white shadow-lg border rounded-lg p-8">
          <p className="text-lg leading-7 text-gray-800 mb-6">
            Thirty3 Options was born out of a vision to transform the financial landscape in emerging markets, particularly across Africa. We saw the immense potential of FinTech to revolutionize how people access and manage their finances, but we also recognized the unique challenges that come with operating in these dynamic and often complex environments.
          </p>
          <p className="text-lg leading-7 text-gray-800 mb-6">
            Founded by a group of seasoned professionals with deep expertise in both finance and technology, Thirty3 Options was established to bridge the gap between innovative FinTech solutions and the realities of emerging markets. Our journey began with a simple yet powerful belief: that technology, when harnessed effectively, has the power to drive inclusive growth and create opportunities where they are most needed.
          </p>
          <p className="text-lg leading-7 text-gray-800 mb-6">
            From the outset, our mission has been clear—to empower FinTech companies to navigate the complexities of these markets and achieve sustainable success. We understand that each market is different, with its own regulatory frameworks, cultural nuances, and economic conditions. That’s why we’ve made it our business to understand these intricacies inside and out, ensuring that the guidance we provide is not only strategic but also practical and actionable.
          </p>
        </div>
      </div>
      {/* <Team /> */}
    </section>
  );
}
