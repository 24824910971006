import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

function Header() {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="flex shadow-md gap-5 items-center justify-between px-16 py-3.5 w-full font-medium bg-white max-md:flex-wrap max-md:px-5 max-md:max-w-full">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/680371522f7059d7c151b61613f3a006288fd699ac04dd67fedaf53faeea2bf8?apiKey=c4b289b0ae264c378a51e310c1d6d054&"
        className="shrink-0 max-w-full aspect-[1.45] w-[104px] max-md:w-[80px]"
        alt="Site Logo"
      />
      <button
        className="md:hidden flex items-center px-3 py-2 border rounded text-gray-700 border-gray-700 hover:text-gray-900 hover:border-gray-900"
         onClick={toggleMenu}
      >
        <svg className="fill-current h-3 w-3" viewBox="0 0 20 20">
          <title>Menu</title>
          <path d="M0 3h20v2H0zM0 9h20v2H0zM0 15h20v2H0z" />
        </svg>
      </button>
      <nav className={`flex-col md:flex-row gap-5 my-auto ${isOpen ? 'flex' : 'hidden'} md:flex max-md:flex-wrap max-md:w-full`}>
        <ul className="flex flex-col md:flex-row grow shrink-0 justify-center self-end mt-6 text-base leading-8 text-gray-900 basis-0 w-fit max-md:w-full max-md:gap-3 max-md:mt-0">
          <li className="flex flex-col md:flex-row gap-5 max-md:gap-3">
            <Link  onClick={toggleMenu} to="/" className="max-md:text-sm">Home</Link>
            <Link  onClick={toggleMenu} to="/about" className=" max-md:text-sm">About</Link>
            <Link  onClick={toggleMenu} to="/services" className="max-md:text-sm">Services</Link>
           </li>
        </ul>
        <Link to="/contact">
        <button className="md:justify-center md:h-[40px] md:mt-4 text-sm md:px-4 md:text-center md:text-white md:uppercase md:shadow-md md:bg-[linear-gradient(90deg,#D19D2A_0%,#E3C257_50.39%,#D19D2A_100%)] md:rounded-[100px] md:px-5 md:mt-4  md:w-[150px] max-md:hiddenn   md:ml-0 max-md:order-first">
          Contact Us
        </button>
        </Link>
      </nav>
    </header>
  );
}

export default Header;
